import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Container from 'components/Container';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Segment from 'components/Segment';

import './NotFound.scss';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found | Andrejs Gubars | Edinburgh</title>
      </Helmet>
      <Segment primary className="not-found">
        <Container isFullWidth className="full-height centered">
          <Heading as="h1" className="inverted">
            404
          </Heading>
          <Text as="p">Page Not Found</Text>
          <Link className="button negative" to="/">
            Back To Homepage
          </Link>
        </Container>
      </Segment>
    </>
  );
};

export default NotFound;
