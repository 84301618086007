import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';

import getElementType from 'components/Element';

import './Text.scss';

const Text = React.forwardRef((props, ref) => {
  const ElementType = getElementType(Text, props);
  const classes = cx('text', props.className);

  return (
    <ElementType className={classes} ref={ref}>
      {props.children}
    </ElementType>
  );
});

Text.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Text;
