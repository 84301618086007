import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';

import './Segment.scss';

const Segment = ({ children, className, primary, secondary, minPadded }) => {
  const classes = cx('segment', className, {
    primary,
    secondary,
    'small-padding': minPadded,
  });

  return <div className={classes}>{children}</div>;
};

Segment.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Segment;
