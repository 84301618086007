import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';

import './Textarea.scss';

const Textarea = ({ error, className, ...props }) => {
  const classes = cx('textarea-container', className, { 'has-error': !!error });

  return (
    <div className={classes}>
      <textarea className="textarea" {...props} />
      <span className="error">{error}</span>
    </div>
  );
};

Textarea.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
};

export default Textarea;
