import React from 'react';
import PropTypes from 'prop-types';

import './Avatar.scss';

const Avatar = ({ src, alt, height, width }) => {
  return (
    <div className="avatar">
      <img src={src} alt={alt} height={height} width={width} />
    </div>
  );
};

Avatar.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Avatar;
