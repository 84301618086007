import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';

import './Row.scss';

const Row = ({ children, className }) => {
  const classes = cx('row', className);

  return <div className={classes}>{children}</div>;
};

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Row;
