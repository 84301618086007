import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';

import './Code.scss';

const Code = ({ children, className, inverted }) => {
  const classes = cx('code', className, { inverted });

  return <code className={classes}>{children}</code>;
};

Code.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  inverted: PropTypes.bool,
};

export default Code;
