import React from 'react';

import './Ring.scss';

const Ring = () => {
  return (
    <div className="ring">
      <div className="inner-ring" />
    </div>
  );
};

export default Ring;
