import React from 'react';
import { AiFillLinkedin, AiFillGithub } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import Container from 'components/Container';
import Text from 'components/Text';
import Segment from 'components/Segment';
import Anchor from 'components/Anchor';
import getElementType from 'components/Element';

import './Footer.scss';

const Footer = () => {
  const ButtonsContainer = getElementType('div', { as: 'div' });

  return (
    <Segment secondary className="footer">
      <Container>
        <ButtonsContainer className="footer-icons">
          <Anchor
            href="https://github.com/n1ghtie"
            rel="noreferrer"
            target="_blank"
            className="icon-button"
            aria-label="Andrejs Gubars GitHub"
          >
            <AiFillGithub size="30" />
          </Anchor>
          <Anchor
            href="https://uk.linkedin.com/in/andrejsgubars"
            rel="noreferrer"
            target="_blank"
            className="icon-button"
            aria-label="Andrejs Gubars LinkedIn"
          >
            <AiFillLinkedin size="30" />
          </Anchor>
        </ButtonsContainer>
        <Text as="p">
          © All rights reserved.{' '}
          <Link to="/" className="color-red">
            Andrejs Gubars
          </Link>{' '}
          | Senior Front-end Developer
        </Text>
      </Container>
    </Segment>
  );
};

export default Footer;
