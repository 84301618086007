import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';

import Segment from 'components/Segment';
import Heading from 'components/Heading';
import Row from 'components/Row';
import Col from 'components/Col';
import Container from 'components/Container';
import Input from 'components/Input';
import Text from 'components/Text';
import Button from 'components/Button';
import getElementType from 'components/Element';
import Textarea from 'components/Textarea';
import Code from 'components/Code';

import Footer from 'containers/Footer';

import api from '../../api';

import './Contact.scss';

const toastConfig = {
  position: 'bottom-center',
  duration: 5000,
};

const Contact = () => {
  const ButtonsContainer = getElementType('div', { as: 'div' });
  const ContactForm = getElementType('form', { as: 'form' });

  const contactFormRef = React.useRef();
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const { pathname, state } = useLocation();

  React.useEffect(() => {
    if (state?.id === 'contact-form') {
      contactFormRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [pathname, state]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    setLoading(true);
    setError(undefined);

    api
      .post('/api/enquiry', Object.fromEntries(data.entries()))
      .then(() => {
        toast.success('Thank you! I will get back to you ASAP.', toastConfig);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.response?.statusText || e?.message, toastConfig);
        setError(e.response?.data?.errors);
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Contact | Andrejs Gubars | Edinburgh</title>
        <link rel="canonical" href="https://andrejsgubars.co.uk/contact" />
        <meta property="og:url" content="https://andrejsgubars.co.uk/contact" />
        <meta
          name="description"
          content="Get in touch with an experienced Senior Software Engineer in Edinburgh. Reach out to discuss software development, code optimization, and technical solutions. Let's collaborate on your projects!"
        />
        <meta
          name="keywords"
          content="Contact Senior Software Engineer, Edinburgh Software Development, Software Project Collaboration, Edinburgh Tech Professional, Edinburgh Software Expert"
        />
        <meta
          property="og:title"
          content="Get in Touch with an Expert Senior Software Engineer in Edinburgh"
        />
        <meta
          property="og:description"
          content="Connect with an experienced Senior Software Engineer based in Edinburgh. Discuss software solutions, code optimization, and technical expertise. Let's collaborate on your software projects today."
        />
        <meta
          property="twitter:title"
          content="Get in Touch with an Expert Senior Software Engineer in Edinburgh"
        />
        <meta
          property="twitter:description"
          content="Connect with an experienced Senior Software Engineer based in Edinburgh. Discuss software solutions, code optimization, and technical expertise. Let's collaborate on your software projects today."
        />
        <meta
          property="og:image"
          content="https://andrejsgubars.co.uk/agn-original.png"
        />
        <meta
          name="twitter:image"
          content="https://andrejsgubars.co.uk/agn-original.png"
        />
      </Helmet>
      <Toaster />
      <Segment primary className="centered">
        <Container>
          <Heading as="h1" className="inverted hero-heading">
            Get in <Code>Touch</Code>
          </Heading>
          <ButtonsContainer className="heading-buttons">
            <Link to="/" className="button normal default">
              Check my homepage
            </Link>
          </ButtonsContainer>
        </Container>
      </Segment>
      <Segment secondary className="hero">
        <Container>
          <Row>
            <Col className="left">
              <Heading as="h2">Don’t Be Shy!</Heading>
            </Col>
            <Col className="right">
              <Text as="p">
                Don't hesitate to get in touch for collaborations, questions, or
                just to say hi! To make things easier for you, Just leave your
                email and message in the form down below!
              </Text>
            </Col>
          </Row>
        </Container>
      </Segment>
      <Segment secondary>
        <Heading as="h3" className="align-center">
          Just Drop me a Message
        </Heading>
        <Container>
          <ContactForm
            ref={contactFormRef}
            onSubmit={handleSubmit}
            className="contact-form"
            id="contact-form"
          >
            <Input
              type="email"
              name="email"
              placeholder="your@email.co.uk"
              required
              error={error?.email}
              readOnly={loading}
            />
            <Textarea
              name="user_message"
              rows={5}
              placeholder="Your message"
              required
              error={error?.user_message}
              readOnly={loading}
            />
            <Button
              type="submit"
              variant="inverted"
              disabled={loading}
              loading={loading}
            >
              Send
            </Button>
          </ContactForm>
        </Container>
      </Segment>
      <Footer />
    </>
  );
};

export default Contact;
