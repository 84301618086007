import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';

import './Container.scss';

const Container = ({ children, className, isFullWidth = false }) => {
  const classes = cx('container', className, { 'full-width': isFullWidth });

  return <div className={classes}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isFullWidth: PropTypes.bool,
};

export default Container;
