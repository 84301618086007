import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';

import getElementType from 'components/Element';

import './Anchor.scss';

const Anchor = React.forwardRef((props, ref) => {
  const ElementType = getElementType(Anchor, props);
  const classes = cx('anchor', props.className);

  return (
    <ElementType {...props} className={classes} ref={ref}>
      {props.children}
    </ElementType>
  );
});

Anchor.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Anchor;
