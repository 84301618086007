import Home from './pages/Home';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import React from 'react';

export const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
