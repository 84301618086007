import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import './Button.scss';

const Button = ({
  children,
  className,
  onClick,
  variant,
  size,
  type,
  disabled,
  loading,
}) => {
  const classes = cx('button', className, variant, size, { disabled, loading });

  return (
    <button
      className={classes}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {loading ? (
        <AiOutlineLoading3Quarters className="loading-icon" />
      ) : (
        children
      )}
    </button>
  );
};

Button.defaultProps = {
  variant: 'default',
  size: 'normal',
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
