import React from 'react';
import { AiFillLinkedin, AiFillGithub } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Container from 'components/Container';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Segment from 'components/Segment';
import Avatar from 'components/Avatar';
import Row from 'components/Row';
import Col from 'components/Col';
import Code from 'components/Code';
import Anchor from 'components/Anchor';
import getElementType from 'components/Element';

import Ring from 'containers/Ring';
import Footer from 'containers/Footer';

import './Home.scss';

const Home = () => {
  const ButtonsContainer = getElementType('div', { as: 'div' });

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>Senior Front-End Developer | Andrejs Gubars | Edinburgh</title>
        <link rel="canonical" href="https://andrejsgubars.co.uk" />
        <meta property="og:url" content="https://andrejsgubars.co.uk" />
        <meta
          name="description"
          content="Experienced Senior Software Engineer in Edinburgh | Transforming Ideas into Innovative Solutions | Expertise in Software Development, Code Optimization, and Problem Solving | Let's Elevate Your Projects Together!"
        />
        <meta
          name="keywords"
          content="Senior Front-End Developer, Front-End Developer, Edinburgh, Web Development, Software Engineer, Code Optimization, Full Stack Development"
        />
        <meta
          property="og:title"
          content="Senior Front-End Developer | Andrejs Gubars | Edinburgh"
        />
        <meta
          property="og:description"
          content="I am a skilled Senior Front-End Developer based in Edinburgh. View my CV to learn about my experience and expertise in web development."
        />
        <meta
          name="twitter:title"
          content="Senior Front-End Developer | Andrejs Gubars | Edinburgh"
        />
        <meta
          name="twitter:description"
          content="I am a skilled Senior Front-End Developer based in Edinburgh. View my CV to learn about my experience and expertise in web development."
        />
        <meta
          property="og:image"
          content="https://andrejsgubars.co.uk/agn-original.png"
        />
        <meta
          name="twitter:image"
          content="https://andrejsgubars.co.uk/agn-original.png"
        />
      </Helmet>
      <Segment primary className="centered hero">
        <Container>
          <ButtonsContainer className="ring-container">
            <Ring />
          </ButtonsContainer>
          <Heading as="h1" className="inverted align-center hero-heading">
            Edinburgh’s Coding Maestro
          </Heading>
          <ButtonsContainer className="heading-buttons">
            <Link
              to="/contact"
              className="default button normal"
              state={{ id: 'contact-form' }}
            >
              Contact Me
            </Link>
          </ButtonsContainer>
        </Container>
      </Segment>

      <Segment secondary>
        <Container>
          <Row>
            <Col>
              <Avatar
                width="80"
                height="80"
                src="/profile.jpg"
                alt="Andrejs Gubars | Senior Software Engineer from Edinburgh"
              />
            </Col>
            <Col>
              <Heading as="h2">Introduction</Heading>
              <Text as="p">
                Hello, my name is <Code>Andrejs</Code> and I’ve been slinging
                bits of code around for over a decade! Ten-plus years of
                front-end experience brought me from novice to Edinburgh’s
                code-wrangling whiz.
              </Text>
            </Col>
          </Row>
        </Container>
      </Segment>

      <Segment secondary>
        <Container>
          <Row>
            <Col size="lg">
              <Heading as="h2">Skills</Heading>
              <Text as="p">
                My overflowing arsenal of web development weapons include:
                <Code>JavaScript</Code> and <Code>TypeScript</Code>,{' '}
                <Code>React</Code>, <Code>Angular</Code>, <Code>Vue</Code>,{' '}
                <Code>Webpack</Code>, <Code>Sass</Code>, <Code>LESS</Code>,{' '}
                <Code>HTML5</Code>, <Code>CSS3</Code> and much more. In other
                words, I've got all the skills to conquer any front-end
                challenge.
              </Text>
              <ButtonsContainer className="icons">
                <Anchor
                  href="https://github.com/n1ghtie"
                  rel="noreferrer"
                  target="_blank"
                  className="icon-button"
                  aria-label="Andrejs Gubars GitHub"
                >
                  <AiFillGithub size="30" />
                </Anchor>
                <Anchor
                  href="https://uk.linkedin.com/in/andrejsgubars"
                  rel="noreferrer"
                  target="_blank"
                  className="icon-button"
                  aria-label="Andrejs Gubars LinkedIn"
                >
                  <AiFillLinkedin size="30" />
                </Anchor>
              </ButtonsContainer>
            </Col>
            <Col size="sm" />
          </Row>
        </Container>
      </Segment>

      <Segment secondary>
        <Container>
          <Heading as="h2">Experience</Heading>
          <Row>
            <Col position="left">
              <Text as="p">
                From creating bleeding-edge web applications for unicorn
                startups to revamping digital presence for established giants,
                I've done it all. Time and again, I've delivered visuals that
                captivate and code that performs.
              </Text>
            </Col>
            <Col position="right">
              <Text as="p">
                My journey took me through the thriving tech spaces of{' '}
                <Code>Freelance</Code>,{' '}
                <Anchor
                  className="color-red"
                  href="https://caliberi.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  Caliber-i
                </Anchor>
                , and{' '}
                <Anchor
                  className="color-red"
                  href="https://xdesign.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  xDesign
                </Anchor>
                . Along the way, I’ve garnered in-depth knowledge and a seasoned
                perspective into what makes a front-end shine.
              </Text>
            </Col>
          </Row>
        </Container>
      </Segment>

      <Segment secondary>
        <Container>
          <Heading as="h2" className="text-align-center">
            Get in Touch
          </Heading>
          <Text as="p" className="text-align-center">
            Think we'd make a great team? Don’t let distance stand in the way of
            collaboration, let’s chat! Dare to explore the limitless
            possibilities of working with Edinburgh’s very own front-end guru.
          </Text>
          <ButtonsContainer className="heading-buttons">
            <Link
              to="/contact"
              state={{ id: 'contact-form' }}
              className="negative button normal"
              preventScrollReset
            >
              Contact Me
            </Link>
          </ButtonsContainer>
        </Container>
      </Segment>

      <Footer />
    </>
  );
};

export default Home;
