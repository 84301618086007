import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';

import './Col.scss';

const Col = ({ children, className, size, position }) => {
  const classes = cx('col', className, size, position);

  return <div className={classes}>{children}</div>;
};

Col.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
  position: PropTypes.string,
};

export default Col;
